var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-diaoLog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "roomManagementDialog",
          attrs: {
            title: "客房管理",
            visible: _vm.roomManagementDialogVisible,
            top: "5vh",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roomManagementDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "room_detail" }, [
              _c("div", [
                _c("span", [_vm._v("房间类型：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.roomInfo.roomTypeName
                        ? _vm.roomInfo.roomTypeName
                        : "--"
                    )
                  ),
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v("房间号："),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.roomInfo.roomNumber ? _vm.roomInfo.roomNumber : "--"
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "equipment" }, [
              _c("div", { staticClass: "equipment_title" }, [_vm._v("设备")]),
              _c(
                "div",
                { staticClass: "equipment_info scrollHeight" },
                [
                  _vm._l(_vm.getRoomDeviceArr.tc, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "equipment_item" },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.tcName) +
                              "\n              "
                          ),
                          _c("span", [
                            _vm._v(
                              "(实际温度：" + _vm._s(item.actualValue) + "℃)"
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "air_info" },
                          [
                            _c("el-input-number", {
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                min: 16,
                                max: 32,
                                "controls-position": "right",
                                size: "large",
                                disabled:
                                  _vm.operation ||
                                  !_vm.canControl ||
                                  _vm.roomInfo.identity != "noPeople" ||
                                  item.onOff != "on",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeTemp($event, item)
                                },
                              },
                              model: {
                                value: item.settingValue,
                                callback: function ($$v) {
                                  _vm.$set(item, "settingValue", $$v)
                                },
                                expression: "item.settingValue",
                              },
                            }),
                            _c("div", [
                              _c("span", [_vm._v("模式：")]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled:
                                          _vm.operation ||
                                          !_vm.canControl ||
                                          _vm.roomInfo.identity != "noPeople" ||
                                          item.onOff != "on",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeMode($event, item)
                                        },
                                      },
                                      model: {
                                        value: item.valve,
                                        callback: function ($$v) {
                                          _vm.$set(item, "valve", $$v)
                                        },
                                        expression: "item.valve",
                                      },
                                    },
                                    _vm._l(_vm.modeTemp, function (item) {
                                      return _c(
                                        "el-radio-button",
                                        {
                                          key: item.value,
                                          attrs: { label: item.value },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", [
                              _c("span", [_vm._v("风速：")]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled:
                                          _vm.operation ||
                                          !_vm.canControl ||
                                          _vm.roomInfo.identity != "noPeople" ||
                                          item.onOff != "on",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeAirSpeed(
                                            $event,
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.speed,
                                        callback: function ($$v) {
                                          _vm.$set(item, "speed", $$v)
                                        },
                                        expression: "item.speed",
                                      },
                                    },
                                    _vm._l(_vm.speedTemp, function (item) {
                                      return _c(
                                        "el-radio-button",
                                        {
                                          key: item.value,
                                          attrs: { label: item.value },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "3px" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  size: "small",
                                  disabled:
                                    _vm.operation ||
                                    !_vm.canControl ||
                                    _vm.roomInfo.identity != "noPeople",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeAir($event, item)
                                  },
                                },
                                model: {
                                  value: item.onOff,
                                  callback: function ($$v) {
                                    _vm.$set(item, "onOff", $$v)
                                  },
                                  expression: "item.onOff",
                                },
                              },
                              _vm._l(_vm.statusTemp, function (item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._l(_vm.getRoomDeviceArr.fan, function (item, index) {
                    return _c(
                      "div",
                      { key: 10 + index, staticClass: "equipment_item" },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(_vm._s(item.fanName)),
                        ]),
                        item.status == 0
                          ? _c("img", {
                              attrs: {
                                src: require("../../../../static/images/dialog/fan_close2.png"),
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("../../../../static/images/dialog/fan_open.png"),
                              },
                            }),
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  size: "small",
                                  disabled:
                                    _vm.operation ||
                                    !_vm.canControl ||
                                    _vm.roomInfo.identity != "noPeople",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeFan($event, item)
                                  },
                                },
                                model: {
                                  value: item.status,
                                  callback: function ($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              },
                              _vm._l(_vm.switchS, function (item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._l(_vm.getRoomDeviceArr.light, function (item, index) {
                    return _c(
                      "div",
                      { key: 1000 + index, staticClass: "equipment_item" },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(_vm._s(item.lightName)),
                        ]),
                        item.status == 1
                          ? _c("img", {
                              attrs: {
                                src: require("../../../../static/images/dialog/lamp_open.png"),
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("../../../../static/images/dialog/lamp_close.png"),
                              },
                            }),
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  size: "small",
                                  disabled:
                                    _vm.operation ||
                                    !_vm.canControl ||
                                    _vm.roomInfo.identity != "noPeople",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeLight($event, item)
                                  },
                                },
                                model: {
                                  value: item.status,
                                  callback: function ($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              },
                              _vm._l(_vm.switchS, function (item) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm.getRoomDeviceArr.tc == false &&
                  _vm.getRoomDeviceArr.fan == false &&
                  _vm.getRoomDeviceArr.light == false
                    ? _c("span", { staticClass: "noHaveData" }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "roomManagementDialog r3000Diao",
          attrs: {
            title: "客房管理",
            visible: _vm.r3000Show,
            width: "60%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.r3000Show = $event
            },
          },
        },
        [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "room_detail" }, [
              _c("div", [
                _c("span", [_vm._v("房间类型：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.roomInfo.roomTypeName
                        ? _vm.roomInfo.roomTypeName
                        : "--"
                    )
                  ),
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v("房间号："),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.roomInfo.roomNumber ? _vm.roomInfo.roomNumber : "--"
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "equipment" }, [
              _c("div", { staticClass: "equipment_title" }, [_vm._v("设备")]),
              _c(
                "div",
                { staticClass: "equipment_info scrollHeight" },
                [
                  !_vm.operation
                    ? _c(
                        "el-card",
                        { staticClass: "equipment_item equipment_item2" },
                        [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("\n              插卡取电\n              "),
                          ]),
                          _vm.roomInfo.basicstatus == "off"
                            ? _c("div", { staticClass: "bar" }, [
                                _c("div", { staticClass: "HEIGHT" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "unconnected",
                                      staticStyle: { height: "200px" },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            title: "未链接",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("../../../../static/images/icon/link.png"),
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.roomInfo.basicstatus == "on"
                            ? _c("div", { staticClass: "bar" }, [
                                _c("div", { staticClass: "HEIGHT" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "unconnected",
                                      staticStyle: { height: "150px" },
                                    },
                                    _vm._l(
                                      _vm.ckList,
                                      function (itemCk, cIndex) {
                                        return _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.roomInfo.identity ==
                                                  itemCk.value,
                                                expression:
                                                  "roomInfo.identity == itemCk.value",
                                              },
                                            ],
                                            key: cIndex,
                                            attrs: {
                                              size: "mini",
                                              title: itemCk.label,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "room_status",
                                              attrs: { src: itemCk.icon },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "bold" } },
                                    _vm._l(
                                      _vm.ckList,
                                      function (itemCk, cIndex) {
                                        return _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.roomInfo.identity ==
                                                  itemCk.value,
                                                expression:
                                                  "roomInfo.identity == itemCk.value",
                                              },
                                            ],
                                            key: cIndex,
                                          },
                                          [_vm._v(_vm._s(itemCk.label))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.roomInfoData.air, function (item, index) {
                    return _c(
                      "el-card",
                      {
                        key: index,
                        staticClass: "equipment_item equipment_item2",
                      },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.tcName) +
                              "\n              "
                          ),
                          _c("span", [
                            _vm._v(
                              "(实际温度：" + _vm._s(item.actualValue) + "℃)"
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "bar" }, [
                          _c("div", { staticClass: "HEIGHT" }, [
                            item.action == "off"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "unconnected",
                                    staticStyle: { height: "200px" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          title: "未链接",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../../../static/images/icon/link.png"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.action != "off"
                              ? _c(
                                  "div",
                                  { staticClass: "air_info" },
                                  [
                                    _c("el-input-number", {
                                      staticClass: "air_info_input_number",
                                      attrs: {
                                        min: 16,
                                        max: 32,
                                        disabled:
                                          _vm.operation ||
                                          !_vm.canControl ||
                                          _vm.roomInfo.identity != "noPeople" ||
                                          item.onOff != "on",
                                        "controls-position": "right",
                                        size: "large",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changesValueR3000(
                                            $event,
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.settingValue,
                                        callback: function ($$v) {
                                          _vm.$set(item, "settingValue", $$v)
                                        },
                                        expression: "item.settingValue",
                                      },
                                    }),
                                    _c("div", { staticClass: "MT" }, [
                                      _c("span", [_vm._v("模式：")]),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                size: "mini",
                                                disabled:
                                                  _vm.operation ||
                                                  !_vm.canControl ||
                                                  _vm.roomInfo.identity !=
                                                    "noPeople" ||
                                                  item.onOff != "on",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeModeR3000(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.valve,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "valve", $$v)
                                                },
                                                expression: "item.valve",
                                              },
                                            },
                                            _vm._l(
                                              _vm.modeTemp,
                                              function (item) {
                                                return _c(
                                                  "el-radio-button",
                                                  {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "MT" }, [
                                      _c("span", [_vm._v("风速：")]),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                size: "mini",
                                                disabled:
                                                  _vm.operation ||
                                                  !_vm.canControl ||
                                                  _vm.roomInfo.identity !=
                                                    "noPeople" ||
                                                  item.onOff != "on",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeSpeedR3000(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.speed,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "speed", $$v)
                                                },
                                                expression: "item.speed",
                                              },
                                            },
                                            _vm._l(
                                              _vm.speedTemp,
                                              function (item) {
                                                return _c(
                                                  "el-radio-button",
                                                  {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.action != "off"
                              ? _c(
                                  "div",
                                  { staticClass: "MT" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: {
                                          size: "mini",
                                          disabled:
                                            _vm.operation ||
                                            !_vm.canControl ||
                                            _vm.roomInfo.identity != "noPeople",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changesOnOffR3000(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.onOff,
                                          callback: function ($$v) {
                                            _vm.$set(item, "onOff", $$v)
                                          },
                                          expression: "item.onOff",
                                        },
                                      },
                                      _vm._l(_vm.statusTemp, function (item) {
                                        return _c(
                                          "el-radio-button",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._l(_vm.roomInfoData.dimming, function (item, index) {
                    return _c(
                      "el-card",
                      {
                        key: index + 200,
                        staticClass: "equipment_item equipment_item2",
                      },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.eqName) +
                              "\n            "
                          ),
                        ]),
                        item.action == "off"
                          ? _c("div", { staticClass: "bar" }, [
                              _c("div", { staticClass: "HEIGHT" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "unconnected",
                                    staticStyle: { height: "200px" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          title: "未链接",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../../../static/images/icon/link.png"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        item.action != "off"
                          ? _c("div", { staticClass: "bar" }, [
                              _c("div", { staticClass: "tgItem tgFlex2" }, [
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-dengpao lightSize",
                                  style: {
                                    color: item.rgb ? item.rgb : "#ffeb00",
                                    filter:
                                      "brightness(" +
                                      item.brightness +
                                      "%)saturate(" +
                                      item.colorTemperature +
                                      "%)",
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "tgItem" },
                                [
                                  _c("div", { staticClass: "tgType" }, [
                                    _vm._v("亮度"),
                                  ]),
                                  _c("el-slider", {
                                    attrs: {
                                      step: 10,
                                      disabled: "",
                                      vertical: "",
                                      height: "200px",
                                    },
                                    model: {
                                      value: item.brightness,
                                      callback: function ($$v) {
                                        _vm.$set(item, "brightness", $$v)
                                      },
                                      expression: "item.brightness",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "tgItem" },
                                [
                                  _c("div", { staticClass: "tgType" }, [
                                    _vm._v("色温"),
                                  ]),
                                  _c("el-slider", {
                                    attrs: {
                                      step: 10,
                                      disabled: "",
                                      vertical: "",
                                      height: "200px",
                                    },
                                    model: {
                                      value: item.colorTemperature,
                                      callback: function ($$v) {
                                        _vm.$set(item, "colorTemperature", $$v)
                                      },
                                      expression: "item.colorTemperature",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "tgItem" },
                                [
                                  _c("div", { staticClass: "tgType" }, [
                                    _vm._v("rgb"),
                                  ]),
                                  _c("el-color-picker", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.rgb,
                                      callback: function ($$v) {
                                        _vm.$set(item, "rgb", $$v)
                                      },
                                      expression: "item.rgb",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._l(_vm.roomInfoData.panel, function (items, idx) {
                    return _c(
                      "el-card",
                      {
                        key: idx + 500,
                        staticClass: "equipment_item equipment_item2",
                      },
                      [
                        _c("div", { staticClass: "item_title" }, [
                          _vm._v(_vm._s(items.eqName)),
                        ]),
                        items.action == "off"
                          ? _c("div", { staticClass: "bar" }, [
                              _c("div", { staticClass: "HEIGHT" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "unconnected",
                                    staticStyle: { height: "200px" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          title: "未链接",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../../../static/images/icon/link.png"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "bar",
                                class: { barItem: items.list.length > 3 },
                              },
                              _vm._l(items.list, function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "content-item",
                                    class: "lengthItem" + items.list.length,
                                  },
                                  [
                                    _c(
                                      "el-card",
                                      { staticClass: "contentBar" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "name",
                                            class: {
                                              openColor: item.action == "on",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.loopName) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "SwitchBox" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                disabled:
                                                  _vm.operation ||
                                                  !_vm.canControl ||
                                                  _vm.roomInfo.identity !=
                                                    "noPeople",
                                                "active-value": "on",
                                                "inactive-value": "off",
                                                "active-color": "#409EFF",
                                                "inactive-color": "#999",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeSwitch(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.action,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "action", $$v)
                                                },
                                                expression: "item.action",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                      ]
                    )
                  }),
                  _vm.roomInfoData.air.length == 0 &&
                  _vm.roomInfoData.panel.length == 0
                    ? _c("span", { staticClass: "noHaveData" }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }