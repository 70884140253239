<template>
  <div class="my-diaoLog">
    <!-- 客房管理 -->
    <el-dialog
      title="客房管理"
      :visible.sync="roomManagementDialogVisible"
      top="5vh"
      width="80%"
      class="roomManagementDialog"
    >
      <div class="info">
        <div class="room_detail">
          <div>
            <span>房间类型：</span>
            <span>{{
              roomInfo.roomTypeName ? roomInfo.roomTypeName : "--"
            }}</span>
            <span style="margin-left: 20px">房间号：</span>
            <span>{{ roomInfo.roomNumber ? roomInfo.roomNumber : "--" }}</span>
          </div>
        </div>
        <div class="equipment">
          <div class="equipment_title">设备</div>
          <div class="equipment_info scrollHeight">
            <!-- 空调 -->
            <div
              class="equipment_item"
              v-for="(item, index) in getRoomDeviceArr.tc"
              :key="index"
            >
              <div class="item_title">
                {{ item.tcName }}
                <span>(实际温度：{{ item.actualValue }}℃)</span>
              </div>
              <!-- {{airBtn}} -->
              <!-- v-if="item.deviceStatus == '开'" -->
              <div class="air_info">
                <el-input-number
                  v-model="item.settingValue"
                  :min="16"
                  :max="32"
                  controls-position="right"
                  @change="handleChangeTemp($event, item)"
                  size="large"
                  :disabled="
                    operation ||
                    (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                  "
                  style="font-size: 20px"
                ></el-input-number>
                <div>
                  <span>模式：</span>
                  <span>
                    <el-radio-group
                      v-model="item.valve"
                      size="mini"
                      @change="changeMode($event, item)"
                      :disabled="
                        operation ||
                        (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                      "
                    >
                      <el-radio-button
                        v-for="item in modeTemp"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.name }}</el-radio-button
                      >
                    </el-radio-group>
                  </span>
                </div>
                <div>
                  <span>风速：</span>
                  <span>
                    <el-radio-group
                      v-model="item.speed"
                      size="mini"
                      @change="changeAirSpeed($event, item)"
                      :disabled="
                        operation ||
                        (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                      "
                    >
                      <el-radio-button
                        v-for="item in speedTemp"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.name }}</el-radio-button
                      >
                    </el-radio-group>
                  </span>
                </div>
              </div>
              <div style="margin-top: 3px">
                <el-radio-group
                  v-model="item.onOff"
                  size="small"
                  @change="changeAir($event, item)"
                  :disabled="
                    operation ||
                    (!canControl || roomInfo.identity != 'noPeople')
                  "
                >
                  <el-radio-button
                    v-for="item in statusTemp"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <!-- 排气扇 -->
            <div
              class="equipment_item"
              v-for="(item, index) in getRoomDeviceArr.fan"
              :key="10 + index"
            >
              <div class="item_title">{{ item.fanName }}</div>
              <img
                src="../../../../static/images/dialog/fan_close2.png"
                v-if="item.status == 0"
              />
              <img src="../../../../static/images/dialog/fan_open.png" v-else />
              <div>
                <el-radio-group
                  v-model="item.status"
                  size="small"
                  @change="changeFan($event, item)"
                  :disabled="
                    operation ||
                    (!canControl || roomInfo.identity != 'noPeople')
                  "
                >
                  <el-radio-button
                    v-for="item in switchS"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <!-- 灯光 -->
            <div
              class="equipment_item"
              v-for="(item, index) in getRoomDeviceArr.light"
              :key="1000 + index"
            >
              <div class="item_title">{{ item.lightName }}</div>
              <img
                src="../../../../static/images/dialog/lamp_open.png"
                v-if="item.status == 1"
              />
              <img
                src="../../../../static/images/dialog/lamp_close.png"
                v-else
              />
              <div>
                <el-radio-group
                  v-model="item.status"
                  size="small"
                  @change="changeLight($event, item)"
                  :disabled="
                    operation ||
                    (!canControl || roomInfo.identity != 'noPeople')
                  "
                >
                  <el-radio-button
                    v-for="item in switchS"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>

            <span
              v-if="
                getRoomDeviceArr.tc == false &&
                getRoomDeviceArr.fan == false &&
                getRoomDeviceArr.light == false
              "
              class="noHaveData"
              >暂无数据</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="客房管理"
      :visible.sync="r3000Show"
      width="60%"
      top="5vh"
      class="roomManagementDialog r3000Diao"
    >
      <div class="info">
        <div class="room_detail">
          <div>
            <span>房间类型：</span>
            <span>{{
              roomInfo.roomTypeName ? roomInfo.roomTypeName : "--"
            }}</span>
            <span style="margin-left: 20px">房间号：</span>
            <span>{{ roomInfo.roomNumber ? roomInfo.roomNumber : "--" }}</span>
          </div>
        </div>
        <div class="equipment">
          <div class="equipment_title">设备</div>
          <div class="equipment_info scrollHeight">
            <el-card class="equipment_item equipment_item2" v-if="!operation">
              <div class="item_title">
                插卡取电
                <!-- {{ roomInfo.eqName }} -->
              </div>
              <div class="bar" v-if="roomInfo.basicstatus == 'off'">
                <div class="HEIGHT">
                  <div class="unconnected" style="height: 200px">
                    <el-button size="mini" title="未链接">
                      <img src="../../../../static/images/icon/link.png" />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="bar" v-if="roomInfo.basicstatus == 'on'">
                <div class="HEIGHT">
                  <div class="unconnected" style="height: 150px">
                    <el-button
                      v-for="(itemCk, cIndex) in ckList"
                      :key="cIndex"
                      size="mini"
                      :title="itemCk.label"
                      v-show="roomInfo.identity == itemCk.value"
                    >
                      <img :src="itemCk.icon" class="room_status" />
                    </el-button>
                  </div>
                  <div class="" style="font-weight:bold;color：#333">
                    <span
                      v-for="(itemCk, cIndex) in ckList"
                      :key="cIndex"
                      v-show="roomInfo.identity == itemCk.value"
                      >{{ itemCk.label }}</span
                    >
                  </div>
                </div>
              </div>
            </el-card>
            <!-- 空调 -->
            <el-card
              v-for="(item, index) in roomInfoData.air"
              :key="index"
              class="equipment_item equipment_item2"
            >
              <div class="item_title">
                {{ item.tcName }}
                <span>(实际温度：{{ item.actualValue }}℃)</span>
              </div>

              <div class="bar">
                <div class="HEIGHT">
                  <div
                    v-if="item.action == 'off'"
                    class="unconnected"
                    style="height: 200px"
                  >
                    <el-button size="mini" title="未链接">
                      <img src="../../../../static/images/icon/link.png" />
                    </el-button>
                  </div>
                  <div class="air_info" v-if="item.action != 'off'">
                    <el-input-number
                      class="air_info_input_number"
                      v-model="item.settingValue"
                      :min="16"
                      :max="32"
                      @change="changesValueR3000($event, item)"
                      :disabled="
                        operation ||
                        (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                      "
                      controls-position="right"
                      size="large"
                    ></el-input-number>
                    <div class="MT">
                      <span>模式：</span>
                      <span>
                        <el-radio-group
                          v-model="item.valve"
                          size="mini"
                          @change="changeModeR3000($event, item)"
                          :disabled="
                            operation ||
                            (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                          "
                        >
                          <el-radio-button
                            v-for="item in modeTemp"
                            :key="item.value"
                            :label="item.value"
                            >{{ item.name }}</el-radio-button
                          >
                        </el-radio-group>
                      </span>
                    </div>
                    <div class="MT">
                      <span>风速：</span>
                      <span>
                        <el-radio-group
                          v-model="item.speed"
                          @change="changeSpeedR3000($event, item)"
                          size="mini"
                          :disabled="
                            operation ||
                            (!canControl || roomInfo.identity != 'noPeople' || item.onOff != 'on')
                          "
                        >
                          <el-radio-button
                            v-for="item in speedTemp"
                            :key="item.value"
                            :label="item.value"
                            >{{ item.name }}</el-radio-button
                          >
                        </el-radio-group>
                      </span>
                    </div>
                  </div>

                  <div class="MT" v-if="item.action != 'off'">
                    <el-radio-group
                      v-model="item.onOff"
                      @change="changesOnOffR3000($event, item)"
                      size="mini"
                      :disabled="
                        operation ||
                        (!canControl || roomInfo.identity != 'noPeople')
                      "
                    >
                      <el-radio-button
                        v-for="item in statusTemp"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.name }}</el-radio-button
                      >
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </el-card>

            <el-card
              v-for="(item, index) in roomInfoData.dimming"
              :key="index + 200"
              class="equipment_item equipment_item2"
            >
              <div class="item_title">
                {{ item.eqName }}
              </div>
              <div class="bar" v-if="item.action == 'off'">
                <div class="HEIGHT">
                  <div class="unconnected" style="height: 200px">
                    <el-button size="mini" title="未链接">
                      <img src="../../../../static/images/icon/link.png" />
                    </el-button>
                  </div>
                </div>
              </div>

              <div class="bar" v-if="item.action != 'off'">
                <div class="tgItem tgFlex2">
                  <i
                    class="iconfont icon-dengpao lightSize"
                    :style="{
                      color: item.rgb ? item.rgb : '#ffeb00',
                      filter:
                        'brightness(' +
                        item.brightness +
                        '%)saturate(' +
                        item.colorTemperature +
                        '%)',
                    }"
                  ></i>
                </div>
                <div class="tgItem">
                  <div class="tgType">亮度</div>
                  <el-slider
                    :step="10"
                    v-model="item.brightness"
                    disabled
                    vertical
                    height="200px"
                  >
                  </el-slider>
                </div>
                <div class="tgItem">
                  <div class="tgType">色温</div>
                  <el-slider
                    :step="10"
                    v-model="item.colorTemperature"
                    disabled
                    vertical
                    height="200px"
                  >
                  </el-slider>
                </div>
                <div class="tgItem">
                  <div class="tgType">rgb</div>
                  <el-color-picker
                    v-model="item.rgb"
                    disabled
                  ></el-color-picker>
                </div>
              </div>
            </el-card>

            <!-- 开关 -->
            <el-card
              v-for="(items, idx) in roomInfoData.panel"
              :key="idx + 500"
              class="equipment_item equipment_item2"
            >
              <div class="item_title">{{ items.eqName }}</div>
              <div class="bar" v-if="items.action == 'off'">
                <div class="HEIGHT">
                  <div class="unconnected" style="height: 200px">
                    <el-button size="mini" title="未链接">
                      <img src="../../../../static/images/icon/link.png" />
                    </el-button>
                  </div>
                </div>
              </div>
              <div
                class="bar"
                v-else
                :class="{ barItem: items.list.length > 3 }"
              >
                <div
                  v-for="(item, i) in items.list"
                  :key="i"
                  class="content-item"
                  :class="'lengthItem' + items.list.length"
                >
                  <el-card class="contentBar">
                    <div
                      class="name"
                      :class="{ openColor: item.action == 'on' }"
                    >
                      {{ item.loopName }}
                    </div>
                    <div class="SwitchBox">
                      <el-switch
                        @change="changeSwitch($event, item)"
                        :disabled="
                          operation ||
                          (!canControl || roomInfo.identity != 'noPeople')
                        "
                        v-model="item.action"
                        active-value="on"
                        inactive-value="off"
                        active-color="#409EFF"
                        inactive-color="#999"
                      >
                      </el-switch>
                    </div>
                  </el-card>
                </div>
              </div>
            </el-card>

            <span
              v-if="
                roomInfoData.air.length == 0 && roomInfoData.panel.length == 0
              "
              class="noHaveData"
              >暂无数据</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoomInfo,
  getRoomInfoR3000,
  deviceControlReq,
} from "@/api";
export default {
  props: {
    operation: {
      default: false,
      type: Boolean,
    },
    roomInfo: {
      type: Object,
    },
  },
  data() {
    return {
      roomInfoData: {
        air: [],
        panel: [],
      },
      getRoomDeviceArr: {
        fan: [],
        light: [],
        tc: [],
      },
      //   roomInfo: {
      //   roomTypeName: null,
      //  },
      roomManagementDialogVisible: false,
      r3000Show: false,
      tgCode: "0x8D", //调光面板的code
      tgCode1: "0x8D_01", //调光面板 单亮度调光
      tgCode2: "0x8D_02", //调光面板 亮度色温调光
      tgCode4: "0x8D_04", //调光面板 RGB调光
      tgCode5: "0x8D_05", //调光面板 RGB亮度色温调光
      modeTemp: [
        {
          name: "关闭",
          value: "stop",
        },
        {
          name: "制暖",
          value: "heat",
        },
        {
          name: "制冷",
          value: "cold",
        },
        {
          name: "送风",
          value: "wind",
        },
      ],
      speedTemp: [
        {
          name: "关闭",
          value: "stop",
        },
        {
          name: "低速",
          value: "low",
        },
        {
          name: "中速",
          value: "middle",
        },
        {
          name: "高速",
          value: "high",
        },
      ],
      switchS: [
        {
          name: "开",
          value: "1",
        },
        {
          name: "关",
          value: "0",
        },
      ],
      statusTemp: [
        {
          name: "开",
          value: "on",
        },
        {
          name: "关",
          value: "off",
        },
      ],
    };
  },
  computed: {
    ckList() {
      return this.$store.state.ckList;
    },
    airBtn: function () {
      return this.$store.state.roleMenu.indexOf("air:btn");
    },
    canControl: function () {
      return this.$store.state.roleMenu.indexOf("mqtt:add") > 0;
    },
  },
  methods: {
    changeSwitch(val, item) {
      let data = new Object();
      data.cmd = "panel";
      data.loopNumber = item.loopNumber;
      data.sortNumber = item.sortNumber;
      data.id = this.roomInfo.mainboardId;
      data.onOff = val;
      this.mqReq(data);
    },
    mqReq(data) {
      deviceControlReq(data).then((res) => {
        console.log(res);
        if (res.data.code == "000000") {
          this.$message({
            type: "success",
            message: "操作成功！",
          });
        } else {
          this.$message({
            type: "error",
            message: "操作异常，请联系管理员！",
          });
        }
      });
    },
    // 改变空调状态
    changeAir(oper, val) {
      let tc = val.tcId;
      let onOff = oper;
      let data = {};
      data[tc] = {
        workMode: val.valve,
        speed: val.speed,
        onOff: onOff,
        speedMode: "auto",
        temp: val.settingValue,
      };
      let reqData = {
        cmd: "air",
        id: val.mainboardId,
        index: 1,
        arg: data,
        source: "web",
      };
      this.mqReq(reqData);
    },
    // 更改温度
    handleChangeTemp(oper, val) {
      this.changeAir("on", val);
    },
    // 温度
    changesValueR3000(val, item) {
      let data = this.getAirData(item);
      data.arg[item.tcId].temp = val;
      this.mqReq(data);
    },
    // 模式
    changeModeR3000(val, item) {
      let data = this.getAirData(item);
      data.arg[item.tcId].workMode = val;
      this.mqReq(data);
    },
    // 风速
    changeSpeedR3000(val, item) {
      let data = this.getAirData(item);
      data.arg[item.tcId].speed = val;
      this.mqReq(data);
    },
    // 开关
    changesOnOffR3000(val, item) {
      let data = this.getAirData(item);
      data.arg[item.tcId].onOff = val;
      this.mqReq(data);
    },
    getAirData(item) {
      let data = new Object();
      data.cmd = "other";
      data.id = this.roomInfo.mainboardId;
      data.sortNumber = item.sortNumber;
      data.source = "web";
      data.arg = {};
      data.arg[item.tcId] = {
        temp: item.settingValue,
        speedMode: "auto",
        speed: item.speed,
        onOff: item.onOff,
        workMode: item.valve,
      };
      return data;
    },
    // 改变空调模式
    changeMode(oper, val) {
      this.changeAir("on", val);
    },
    // 改变空调风速
    changeAirSpeed(oper, val) {
      this.changeAir("on", val);
    },
    // 改变排气扇状态
    changeFan(oper, val) {
      let fanArr = JSON.parse(JSON.stringify(this.getRoomDeviceArr.fan));
      let state = this.lightJsonData(fanArr, "排气扇");
      let data = {
        cmd: "light",
        id: val.mainboardId,
        source: "web",
        index: 1,
        arg: state,
        lightId: val.fanId,
        opt: oper
      };
      this.mqReq(data);
    },
    // 改变灯光状态
    changeLight(oper, val) {
      let lightArr = JSON.parse(JSON.stringify(this.getRoomDeviceArr.light));
      let state = this.lightJsonData(lightArr, "灯光");
      let data = {
        cmd: "light",
        id: val.mainboardId,
        source: "web",
        index: 1,
        arg: state,
        lightId: val.lightId,
        opt: oper
      };
      this.mqReq(data);
    },

    // 灯光 json 格式
    lightJsonData(arr, type) {
      let on = [];
      let off = [];
      arr.forEach((item) => {
        if (type == "排气扇") {
          if (item.status == "0") {
            off.push(item.fanId);
          } else {
            on.push(item.fanId);
          }
        } else {
          if (item.status == "0") {
            off.push(item.lightId);
          } else {
            on.push(item.lightId);
          }
        }
      });
      return { on, off };
    },
    // 获取房间设备信息
    getRoomDeviceInfo(val) {
      let str = val ? val.split("-")[0] : null;
      if (str == "WR3000") {
        this.getRoomInfo2(val);
      } else {
        this.getRoomInfo(val);
      }
    },
    // r3000
    getRoomInfo2(val) {
      this.r3000Show = true;
      getRoomInfoR3000(val).then((res) => {
        if (res.data.code == "000000") {
          this.roomInfoData = res.data.data;
          let arr = new Array();
          this.roomInfoData.panel.forEach((item, index) => {
            item.list.forEach((item2, i) => {
              if (item2.eqType.indexOf(this.tgCode) != -1) {
                item2.brightness = parseInt(item2.brightness);
                item2.colorTemperature = parseInt(item2.colorTemperature);
                let obj = Object.assign(item2, item);
                arr.push(obj);
                item.list.splice(i, 1);
              }
            });
            if (item.list.length == 0) {
              this.roomInfoData.panel.splice(index, 1);
            }
          });
          this.roomInfoData.dimming = JSON.parse(JSON.stringify(arr));
          console.log(this.roomInfoData);
        }
      });
    },
    // r2000
    getRoomInfo(val) {
      this.roomManagementDialogVisible = true;
      getRoomInfo(val).then((res) => {
        this.getRoomDeviceArr = res.data.data;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../../../assets/kfjkFont/iconfont.css";
.el-card {
  margin: 0 5px;
}
.unconnected .el-button--mini {
  padding: 0px;
  margin: 0px 3px;
  border: none;
}
.room_icon .el-button--mini:hover,
.room_info .el-button--mini:hover,
.unconnected .el-button--mini:hover {
  background: none;
}
.bar {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  .name {
    width: 100%;
    // height: 100px;
    // line-height: 100px;;
  }
  .tgFlex2 {
    flex: 2 !important;
    font-size: 30px;
  }
  .tgItem {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .tgType {
      writing-mode: lr-tb;
      writing-mode: tb-rl;
    }
  }
}
.openColor {
  color: #409eff !important;
  font-weight: bold;
}
.barItem {
  flex-direction: column;
  flex-wrap: wrap;
}
.content-item {
  flex: 1;
  min-width: 33.3%;
  max-width: 130px;
}
.lengthItem1 {
  max-width: 200px !important;
  width: 200px !important;
}
.lengthItem4,
.lengthItem2 {
  width: 40% !important;
}
.lengthItem1,
.lengthItem2,
.lengthItem3 {
  .name {
    color: #999;
    font-weight: bold;
    padding-top: 20px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // line-height: 120px;
  }
  .SwitchBox {
    padding: 10px 0 20px;
  }
}
.lengthItem4,
.lengthItem5,
.lengthItem6 {
  .SwitchBox {
    padding: 5px 0 5px;
  }
  .name {
    color: #999;
    font-weight: bold;
    padding: 5px 0 5px;
    height: 80px;
    // line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.MT {
  margin-top: 12px;
}
.HEIGHT {
  height: 200px;
  .unconnected {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.lightSize {
  font-size: 80px;
}
.roomManagementDialog .el-dialog__body {
  padding-top: 10px;
}

.roomManagementDialog {
  .room_detail {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  .equipment {
    .equipment_title {
      font-size: 20px;
      color: rgb(64, 158, 255);
      margin-bottom: 5px;
    }
    .scrollHeight {
      max-height: 70vh !important;
    }
    .equipment_info {
      border: 2px solid #f4f4f4;
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 5px;
      overflow-y: scroll;
      .equipment_item {
        text-align: center;
        // flex: 1 1 33%;
        // width: 33.33%;
        width: 24%;
        // min-width: 24%;
        // flex: 1;
        // max-width: 25% ;
        // max-width: 25% ;
        // flex:1;
        // min-width: 280px;
        margin-bottom: 20px;

        img {
          width: 100px;
          height: 100px;
        }
        .item_title {
          height: 32px;
          line-height: 32px;
          margin-bottom: 5px;
          font-size: 20px;
          color: #fff;
          background: #409eff;
          border-left: 1px solid #fff;
          border-right: 1px solid #fff;
          span {
            font-size: 12px;
          }
        }
        .air_info {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          // height: 100px;
        }
      }
      .equipment_item2 {
        // width: auto  !important;
        // flex: 1;
        text-align: center;
        width: 32%;
        margin-bottom: 20px;
      }
      .noHaveData {
        font-size: 20px;
        margin: 0 auto;
        line-height: 270px;
      }
    }
  }
  .roomStatusForm {
    padding-top: 18px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #409eff;
  }
  .lampForm {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #409eff;
    .lamp_title {
      font-size: 20px;
    }
  }
  .temporaryForm {
    border-radius: 5px;
    border: 1px solid #409eff;
    padding-top: 10px;
    .temporary_title {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      div {
        font-size: 0;
        span {
          &:nth-child(1) {
            font-size: 20px;
          }
          &:nth-child(2) {
            font-size: 12px;
            color: #409eff;
          }
        }
      }
    }
    .temperatureInfo {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      padding-right: 10px;
      box-sizing: border-box;
      .setting {
        .setting_info {
          display: inline-flex;
          flex-direction: row;
          width: 100%;
          span {
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>